import { useState, useRef, useEffect, type ReactNode } from "react"
import ReactDOM from "react-dom"
import { useTranslation } from "react-i18next"
import { motion, AnimatePresence } from "framer-motion"
import { disableBodyScroll, enableBodyScroll } from "scroll"

import { MODAL_ROOT_ID } from "src/constants"
import useDialog from "@hooks/useDialog"
import useSubsequentEffect from "@hooks/useSubsequentEffect"

import { ReactComponent as IconClose } from "@icons/close.svg"

interface SidebarProps {
  is_open: boolean
  setIsOpen: (boolean: boolean) => void
  prevent_closing?: boolean
  id: string
  children?: ReactNode
}

const Sidebar = (props: SidebarProps): JSX.Element | null => {
  const [wrapper, setWrapper] = useState<Element | null>(null)
  const { is_open, setIsOpen } = props
  const dialogRef = useRef(null)
  const { t } = useTranslation<string>("common")

  useSubsequentEffect(() => {
    if (is_open) {
      disableBodyScroll(dialogRef.current)
    } else {
      enableBodyScroll(dialogRef.current)
    }
  }, [is_open])

  useEffect(() => {
    const wrapper = document.querySelector(`#${MODAL_ROOT_ID}`)
    setWrapper(wrapper)
  }, [])

  const attr = useDialog({
    id: props.id,
    isOpen: is_open,
  })

  if (!wrapper) {
    return null
  }

  return ReactDOM.createPortal(
    <AnimatePresence>
      {is_open && (
        <motion.div
          {...attr.container}
          className="w-full h-screen fixed left-0 top-0 z-[70]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.3,
          }}
        >
          <div
            {...attr.overlay}
            className="w-full h-screen absolute left-0 top-0 bg-black opacity-30"
            onClick={props.prevent_closing ? undefined : () => setIsOpen(false)}
            data-cy={`sidebar-overlay-close-${props.id}`}
          />
          <motion.div
            {...attr.dialog}
            className="bg-white h-screen w-screen md:w-[48rem] fixed top-0 right-0"
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{
              duration: 0.3,
            }}
          >
            {!props.prevent_closing && (
              <div className="w-full flex justify-end p-4 md:hidden">
                <button className="p-8 rounded-full bg-neutral-100 w-min" onClick={() => setIsOpen(false)}>
                  <div className="w-24">
                    <IconClose />
                    <span className="sr-only">{t("common:common.close")}</span>
                  </div>
                </button>
              </div>
            )}
            <div className="h-screen overflow-y-auto" ref={dialogRef}>
              {props.children}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    wrapper
  )
}

export default Sidebar
