import { type Dispatch, type SetStateAction, useState } from "react"
import { useTranslation } from "react-i18next"

import type { LayoutDef } from "@types"
import { initAnalytics, trackPageView } from "@utils/analytics"
import { closeConsentSettings } from "@stores/consentSettings"

import Button from "@components/Button"
import { ReactComponent as IconCheckCircled } from "@icons/check-circled.svg"
import Card from "@components/Card"
import Tooltip from "@components/Tooltip"

interface ConsentOptionBarProps {
  is_necessary?: boolean
  title: string
  is_cookie_allowed?: boolean
  setIsCookieAllowed?: Dispatch<SetStateAction<boolean>>
  tooltip?: string
  "data-cy"?: string
}

const ConsentOptionBar = (props: ConsentOptionBarProps): JSX.Element => {
  const { is_necessary, title, is_cookie_allowed, setIsCookieAllowed, tooltip } = props

  const { t } = useTranslation<string>("common")

  return (
    <Card class_name="bg-white mb-16 flex items-center justify-between relative h-72 pr-24">
      <div className="flex">
        <p className="leading-none flex my-auto">{title}</p>{" "}
        {tooltip && (
          <div className="ml-6 z-[70] my-auto pt-4">
            <Tooltip text={tooltip} />
          </div>
        )}
      </div>
      {!is_necessary && (
        <div>
          <input
            data-cy={props["data-cy"]}
            type="checkbox"
            id={`check-${title}`}
            checked={is_cookie_allowed}
            onChange={() => setIsCookieAllowed?.(!is_cookie_allowed)}
          />
          <label className="expand-hitbox" htmlFor={`check-${title}`}>
            <span className="sr-only">{title}</span>
          </label>
        </div>
      )}
      {is_necessary && (
        <div className="flex items-center">
          <p className="text-kiwi-dark mr-6">{t("cookies.alwaysActive")}</p>

          <div className="text-kiwi w-16 h-16">
            <IconCheckCircled />
          </div>
        </div>
      )}
    </Card>
  )
}

interface ConsentSettingsProps {
  layout: LayoutDef
  onClose?: () => void
}

const ConsentSettings = (props: ConsentSettingsProps): JSX.Element => {
  const { layout } = props

  const { t } = useTranslation<string>("common")

  const onClickAllow = (allowAll: boolean) => {
    closeConsentSettings()
    localStorage.setItem("consentAccepted", "true")
    if (allowAll || isMarketingCookiesAllowed) {
      localStorage.setItem("marketingConsentAccepted", "true")
      initAnalytics()
      trackPageView()
    }
    props.onClose?.()
  }

  const [isPerformanceCookiesAllowed, setIsPerformanceCookiesAllowed] = useState(false)
  const [isFunctionalCookiesAllowed, setIsFunctionalCookiesAllowed] = useState(false)
  const [isMarketingCookiesAllowed, setIsMarketingCookiesAllowed] = useState(false)

  return (
    <div className="pb-44 md:pb-12 px-16 relative md:px-32" data-cy="cookie-settings">
      <p
        className="text-24 leading-28 mb-24 font-semibold mt-80 md:text-32 md:leading-tight"
        dangerouslySetInnerHTML={{ __html: layout.cookies_details_title }}
      />

      <p className="mb-24 text-base leading-22" dangerouslySetInnerHTML={{ __html: layout.cookies_details_copy }} />

      <Button version="PRIMARY" onClick={() => onClickAllow(true)}>
        {t("cookies.allowAllCookies")}
      </Button>

      <div className="mt-48 md:mt-64">
        <ConsentOptionBar is_necessary title={t("cookies.necessaryCookies")} />

        <div className="mt-16">
          <ConsentOptionBar
            title={t("cookies.performanceCookies")}
            is_necessary={isPerformanceCookiesAllowed}
            setIsCookieAllowed={setIsPerformanceCookiesAllowed}
            tooltip={layout.cookies_performance_tooltip}
            data-cy="performance"
          />
        </div>

        <div className="mt-16">
          <ConsentOptionBar
            title={t("cookies.functionalCookies")}
            is_necessary={isFunctionalCookiesAllowed}
            setIsCookieAllowed={setIsFunctionalCookiesAllowed}
            tooltip={layout.cookies_functional_tooltip}
            data-cy="functional"
          />
        </div>

        <div className="mt-16">
          <ConsentOptionBar
            title={t("cookies.marketingCookies")}
            is_necessary={isMarketingCookiesAllowed}
            setIsCookieAllowed={setIsMarketingCookiesAllowed}
            tooltip={layout.cookies_marketing_tooltip}
            data-cy="marketing"
          />
        </div>
      </div>

      <div className="mt-32">
        <Button version="SECONDARY" onClick={() => onClickAllow(false)} data-cy="confirm">
          {t("cookies.confirmSelection")}
        </Button>
      </div>
    </div>
  )
}

export default ConsentSettings
