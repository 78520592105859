import { useStore } from "@nanostores/react"

import type { LayoutDef } from "@types"
import { $consentSettings, openConsentSettings, closeConsentSettings } from "@stores/consentSettings"

import Sidebar from "@components/Sidebar"
import ConsentSettings from "./ConsentSettings"

interface ConsentSettingsSidebarProps {
  layout: LayoutDef
}

const ConsentSettingsSidebar = (props: ConsentSettingsSidebarProps): JSX.Element => {
  const { is_open } = useStore($consentSettings)

  return (
    <Sidebar
      is_open={is_open}
      setIsOpen={(boolean) => (boolean ? openConsentSettings() : closeConsentSettings())}
      prevent_closing
      id="cookie-settings"
    >
      <ConsentSettings layout={props.layout} />
    </Sidebar>
  )
}

export default ConsentSettingsSidebar
